<template>
	<div class="w-full border-b border-gray-light p-6">
		<div class="flex justify-between cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<span class="icon-list text-2xl text-gray-dark mr-6"></span>
				<h4 class="text-base text-gray-dark font-semibold">PROJECT INFORMATION <span class="text-error">*</span></h4>
			</div>
			<div>
				<p :class="collapse.projectInformation ? 'rotate-180' : 'rotate-0'"
					class="icon-chevron-down text-gray text-2xl cursor-pointer transform transition ease-in"></p>
			</div>
		</div>
		<div v-show="collapse.projectInformation"
			class="mt-6">
			<div class="flex justify-between mb-6 w-full">
				<div class="w-full flex-wrap">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectInformation.background.value) ? 'text-error' : 'text-gray'">
							Background <span class="text-error">*</span>
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'background', 'Background')" v-model="projectInformation.background" :error="errorProjectInformation.background.value">
							<template #message>
								<p>{{ errorProjectInformation.background.message}}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between mb-6 w-full">
				<div class="w-full flex-wrap">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectInformation.tujuanProyek.value) ? 'text-error' : 'text-gray'">
							Tujuan Proyek <span class="text-error">*</span>
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'tujuanProyek', 'Tujuan Proyek')" v-model="projectInformation.tujuanProyek" :error="errorProjectInformation.tujuanProyek.value">
							<template #message>
								<p>{{ errorProjectInformation.tujuanProyek.message}}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between mb-6 w-full">
				<div class="w-full flex-wrap">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectInformation.jangkaWaktuProyek.value) ? 'text-error' : 'text-gray'">
							Jangka Waktu Proyek <span class="text-error">*</span>
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'jangkaWaktuProyek', 'Jangka Waktu Proyek')" v-model="projectInformation.jangkaWaktuProyek" :error="errorProjectInformation.jangkaWaktuProyek.value">
							<template #message>
								<p>{{ errorProjectInformation.jangkaWaktuProyek.message}}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TiptapNonTable from '@/core/components/custom/TiptapNonTable.vue'

export default {
	name: 'ProjectInformation',
	computed: {
		collapse() {
			return this.$store.state.rfp.collapse
		},
		projectInformation: {
			get() {
				return this.$store.state.rfp.projectInformation
			},
			set(value) {
				this.$store.commit('rfp/SET_PROJECT_INFORMATION', value)
			},
		},
		errorProjectInformation: {
			get() {
				return this.$store.state.errorRfp.errorProjectInformation
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROJECT_INFORMATION', value)
			},
		},
	},
	methods: {
		inputUpdate(value, key) {
			this.projectInformation[key] = value
			this.errorProjectInformation[key] = { value: false, message: '' }
		},
		changeCollapse() {
			this.$emit("collapse")
		},
		characterCount(e, key, name) {
			this.$store.dispatch('errorRfp/checkErrorProjectInformationLength', { key, name, totalChar: e, maxLength: 4500 })
    },
	},
	components: {
		TiptapNonTable
	}
}
</script>
