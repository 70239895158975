<template>
	<div class="w-full p-6 border-b border-gray-light">
		<div class="flex justify-between cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<span class="mr-6 text-2xl icon-list text-gray-dark"></span>
				<h4 class="text-base font-semibold text-gray-dark">PROPOSAL INFORMATION <span class="text-error">*</span></h4>
			</div>
			<div>
				<p :class="collapse.proposalInformation ? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
			</div>
		</div>
		<div v-show="collapse.proposalInformation"
			class="mt-6">
			<div class="grid w-full grid-cols-1 gap-6 mb-6 md:grid-cols-3 lg:grid-cols-5">
				<div class="w-full">
					<InputGista label="Project ID"
						type="text"
						:value="pathEditRfp ? proposalInformation.projectId : '(auto-generate)'"
						size="large"
						disabled />
				</div>
				<div class="w-full">
					<InputGista type="text"
						label="No. RFP"
						:value="pathEditRfp ? proposalInformation.noRfp : '(auto-generate)'"
						size="large"
						disabled />
				</div>
				
				<div class="w-full" v-if="!roleKp">
					<SelectGista label="Ada UKKP?"
						:options="['Ya', 'Tidak']"
						:value="proposalInformation.adaUkkp ? 'Ya' : 'Tidak'"
						@change="changeAdaUkkp"
						size="large"
						:disabled="pathEditRfp">
						<template #selected>
							<p>{{ proposalInformation.adaUkkp ? 'Ya' : 'Tidak' }}</p>
						</template>
					</SelectGista>
				</div>
				<div class="w-full" v-if="proposalInformation.adaUkkp">
					<InputGista type="text"
						label="Unit Kerja"
						:value="proposalInformation.unitKerja||'(auto-filled)'"
						size="large"
						disabled />
				</div>
			</div>
			<div class="grid w-full grid-cols-1 gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3">
				<div class="w-full flex flex-col border border-gray-light rounded-lg p-3.5" v-if="!roleOprKp">
					<p class="mb-2.5 text-gray-darkest">GSIT</p>
					<AutocompleteSolutip
						@updateInput="inputSearchApprover($event, 'reviewerGsit')"
						@updateAutoComplete="updateApprover($event, 'reviewerGsit')"
						@focus="focusApprover('reviewerGsit')"
						@blur="blurApprover('reviewerGsit')"
						:options="approverUkkp"
						:isLoading="isLoadingApprover"
						iconLeft="icon-search text-xl text-gray-light"
						placeholder="(Search)"
						v-model="reviewerGsitLocalState"
						:disabled="pathEditRfp && !!proposalInformationProjectDetail.reviewerGsitId"
						textField="name"
						label="Reviewer GSIT"
						class="mb-6"
					>
					</AutocompleteSolutip>
					<AutocompleteSolutip
						@updateInput="inputSearchApprover($event, 'directApprover')"
						@updateAutoComplete="updateApprover($event, 'directApprover')"
						@focus="focusApprover('directApprover')"
						@blur="blurApprover('directApprover')"
						:options="approverUkkp"
						:isLoading="isLoadingApprover"
						iconLeft="icon-search text-xl text-gray-light"
						placeholder="(Search)"
						v-model="directApproverLocalState"
						mandatory
						:error="errorProposalInformation.directApproverId?.value"
						:disabled="pathEditRfp"
						textField="name"
						label="Approver GSIT"
					>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorProposalInformation.directApproverId.message }}</p>
						</template>
					</AutocompleteSolutip>
				</div>
				<div class="w-full flex flex-col border border-gray-light rounded-lg p-3.5" v-if="proposalInformation.adaUkkp || roleOprKp">
					<p class="mb-2.5 text-gray-darkest">UKKP</p>
					<AutocompleteSolutip
						@updateInput="inputSearchApprover($event, 'reviewerUkkp')"
						@updateAutoComplete="updateApprover($event, 'reviewerUkkp')"
						@focus="focusApprover('reviewerUkkp')"
						@blur="blurApprover('reviewerUkkp')"
						:options="approverUkkp"
						:isLoading="isLoadingApprover"
						iconLeft="icon-search text-xl text-gray-light"
						placeholder="(Search)"
						v-model="reviewerUkkpLocalState"
						:disabled="pathEditRfp && !!proposalInformationProjectDetail.reviewerUkkpId"
						textField="name"
						label="Reviewer UKKP"
						class="mb-6"
					>
					</AutocompleteSolutip>
					<AutocompleteSolutip
						label="Approver UKKP"
						placeholder="(Search)"
						:mandatory="proposalInformation.adaUkkp || roleOprKp"
						:options="approverUkkp"
						@updateInput="inputSearchApprover($event, 'approverUkkp')"
						@updateAutoComplete="updateApprover($event, 'approverUkkp')"
						@blur="blurApprover('approverUkkp')"
						@focus="focusApprover('approverUkkp')"
						:isLoading="isLoadingApprover"
						v-model="approverUkkpLocalState"
						:disabled="(!proposalInformation.adaUkkp && !roleOprKp) || pathEditRfp"
						:error="errorProposalInformation.approverUkkpId?.value"
						textField="name"
						iconLeft="icon-search text-xl text-gray-light"
					>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorProposalInformation.approverUkkpId.message }}</p>
						</template>
					</AutocompleteSolutip>
				</div>
				<div class="w-full flex flex-col border border-gray-light rounded-lg p-3.5">
					<p class="mb-2.5 text-gray-darkest">DLOG</p>
					<AutocompleteSolutip
						@updateInput="inputSearchApprover($event, 'firstApproverDlog')"
						@updateAutoComplete="updateApprover($event, 'firstApproverDlog')"
						@focus="focusApprover('firstApproverDlog')"
						@blur="blurApprover('firstApproverDlog')"
						:options="approverUkkp"
						:isLoading="isLoadingApprover"
						iconLeft="icon-search text-xl text-gray-light"
						placeholder="(Search)"
						v-model="firstApproverDlogLocalState"
						mandatory
						:error="errorProposalInformation.firstApproverDlogId?.value"
						:disabled="pathEditRfp"
						textField="name"
						label="Approver 1 ITP"
						class="mb-6"
					>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorProposalInformation.firstApproverDlogId.message }}</p>
						</template>
					</AutocompleteSolutip>
					<AutocompleteSolutip
						@updateInput="inputSearchApprover($event, 'secondApproverDlog')"
						@updateAutoComplete="updateApprover($event, 'secondApproverDlog')"
						@focus="focusApprover('secondApproverDlog')"
						@blur="blurApprover('secondApproverDlog')"
						:options="approverUkkp"
						:isLoading="isLoadingApprover"
						iconLeft="icon-search text-xl text-gray-light"
						placeholder="(Search)"
						v-model="secondApproverDlogLocalState"
						mandatory
						:error="errorProposalInformation.secondApproverDlogId?.value"
						:disabled="pathEditRfp"
						textField="name"
						label="Approver 2 ITP"
					>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorProposalInformation.secondApproverDlogId.message }}</p>
						</template>
					</AutocompleteSolutip>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<ValidationProvider name="Project Name"
						rules="max:200"
						v-slot="{ errors }">
						<InputGista type="text"
							label="Project Name"
							v-model="proposalInformation.projectName"
							:error="!!errors[0] || errorProposalInformation.projectName?.value"
							size="large"
							mandatory>
							<template #message>
								<p>{{ errors[0] || errorProposalInformation.projectName.message }}</p>
							</template>
						</InputGista>
					</ValidationProvider>

				</div>
			</div>
			<div class="grid w-full grid-cols-1 gap-6 mb-6 md:grid-cols-2"
				:class="{ 'lg:grid-cols-5': windowWidth >= 1800, 'lg:grid-cols-3': windowWidth < 1800 }">
				<div class="">
					<InputGista disabled
						type="text"
						label="Technical Coordinator Name"
						size="large"
						:value="proposalInformation.technicalCoordinatorName" />
				</div>
				<div class="">
					<InputGista disabled
						type="text"
						label="Technical Coordinator Email"
						size="large"
						:value="proposalInformation.technicalCoordinatorEmail" />
				</div>
				<div class="">
					<ValidationProvider name="Technical Coordinator Mobile Phone"
						rules="maxDigit:15"
						v-slot="{ errors }">
						<InputSolutip type="text"
							label="Technical Coordinator Mobile Phone"
							placeholder="087777777777"
							size="large"
							v-model="proposalInformation.technicalCoordinatorMobilePhone"
							@keypress="validationPhone($event, proposalInformation.technicalCoordinatorMobilePhone)"
							:error="!!errors[0]">
							<template #message>
								<p>{{ errors[0] }}</p>
							</template>
						</InputSolutip>
					</ValidationProvider>

				</div>
				<div class="">
					<ValidationProvider name="Technical Coordinator Office Phone"
						rules="maxDigit:15"
						v-slot="{ errors }">
						<InputSolutip type="text"
							label="Technical Coordinator Office Phone"
							placeholder="02125563000"
							size="large"
							v-model="proposalInformation.technicalCoordinatorOfficePhone"
							@keypress="validationPhone($event, proposalInformation.technicalCoordinatorOfficePhone)"
							:error="!!errors[0] || errorProposalInformation.technicalCoordinatorOfficePhone?.value"
							mandatory>
							<template #message>
								<p>{{ errors[0] || errorProposalInformation.technicalCoordinatorOfficePhone.message }}</p>
							</template>
						</InputSolutip>
					</ValidationProvider>

				</div>
				<div class="">
					<ValidationProvider name="Ext"
						rules="maxDigit:7"
						v-slot="{ errors }">
						<InputSolutip type="text"
							placeholder="1012345"
							label="Ext"
							size="large"
							v-model="proposalInformation.ext"
							@keypress="validationNumber"
							:error="!!errors[0] || errorProposalInformation.ext?.value"
							mandatory>
							<template #message>
								<p>{{ errors[0] || errorProposalInformation.ext.message }}</p>
							</template>
						</InputSolutip>
					</ValidationProvider>

				</div>
			</div>
			<div class="grid w-full grid-cols-1 gap-6 mb-6 md:grid-cols-3">
				<div class="">
					<ValidationProvider name="Budget Spent"
						rules="maxBudgetSpent"
						v-slot="{ errors }">
						<InputSolutip type="text"
							label="Budget Spent (IDR)"
							placeholder="1.000.000.000"
							size="large"
							v-model="proposalInformation.budgetSpent"
							@input="inputBudgetSpent"
							@handleInput="handleInputBudgetSpent"
							@keypress="validationBudgetSpent"
							:error="!!errors[0] || errorProposalInformation.budgetSpent?.value"
							mandatory>
							<template #message>
								<p>{{ errors[0] || errorProposalInformation.budgetSpent.message }}</p>
							</template>
						</InputSolutip>
					</ValidationProvider>

				</div>
				<div class="">
					<ValidationProvider name="Kode Wbs Level 1"
						rules="max:200"
						v-slot="{ errors }">
						<AutocompleteSolutip
							@updateInput="inputSearchWBS"
							@updateAutoComplete="updateWBS"
							:options="WBS"
							placeholder="(Search)"
							@focus="focusKodeWBSLev1"
							@blur="blurKodeWBSLev1"
							:isLoading="isLoadingWBS"
							iconLeft="icon-search text-xl text-gray-light"
							v-model="kodeWbsLev1LocalState"
							textField="wbsElement"
							label="Kode WBS Level 1"
							:error="!!errors[0] || errorProposalInformation.kodeWbsLev1?.value"
							mandatory
						>
							<template #message>
								<p>{{errors[0] || errorProposalInformation.kodeWbsLev1.message }}</p>
							</template>
						</AutocompleteSolutip>
					</ValidationProvider>

				</div>
				<div class="">
					<label class="text-gray">Jenis Pengadaan</label>
					<div class="flex items-center h-14">
						<GistaCheckbox @change="changeCheckBox"
							:modelValue="proposalInformation.isSoftware" />
						<label class="ml-3 text-gray">
							Software Application
						</label>
					</div>
				</div>
			</div>
			<div class="flex w-full mb-6">
				<div class="flex-wrap w-full">
					<ValidationProvider name="Notes"
						rules="max:300"
						v-slot="{ errors }">
						<TextareaGista label="Notes (Optional)"
							placeholder="Leave a comment here"
							v-model="proposalInformation.notes"
							:error="!!errors[0]">
							<template #message>
								<p>{{ errors[0] }}</p>
							</template>
						</TextareaGista>
					</ValidationProvider>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AutocompleteSolutip from '@/core/components/custom/Autocomplete.vue'
import InputSolutip from '@/core/components/custom/Input.vue'
import { KP, SOLUTIP_OPR_KP } from '@/core/constant/applicationRoleName.js'
import { EDITRFP } from '@/core/constant/routeName'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export default {
    name: "ProposalInformation",
    data() {
        return {
            windowWidth: window.innerWidth,
						SOLUTIP_OPR_KP: SOLUTIP_OPR_KP,
						timeout: null,
						isLoadingApprover: false,
						isLoadingWBS: false,
						mapApproverType: {
							reviewerGsit: 'REVIEWER_GSIT',
							reviewerUkkp: 'REVIEWER_UKKP',
							firstApproverDlog: 'APPROVER_DLOG_1',
							secondApproverDlog: 'APPROVER_DLOG_2',
							directApprover: 'APPROVER_GSIT',
							approverUkkp: 'APPROVER_UKKP',
						},
						directApproverLocalState: '',
						approverUkkpLocalState: '',
						reviewerGsitLocalState: '',
						reviewerUkkpLocalState: '',
						firstApproverDlogLocalState: '',
						secondApproverDlogLocalState: '',
						kodeWbsLev1LocalState: '',

        };
    },
    computed: {
        userDetail() {
            return this.$store.state.dashboard.userProfile;
        },
        collapse() {
            return this.$store.state.rfp.collapse;
        },
        approverUkkp() {
          return this.$store.state.rfp.approverUkkp.filter(e => !this.approversForFilter.includes(e.userName));
        },
        approverUkkpName() {
            return this.$store.state.rfp.approverUkkp.map(e => e.name);
        },
				approversForFilter() {
					let approvers = []
					if (this.directApproverLocalState) {
						approvers.push(this.proposalInformation.directApproverId)
					}
					if (this.approverUkkpLocalState) {
						approvers.push(this.proposalInformation.approverUkkpId)
					}
					if (this.reviewerGsitLocalState) {
						approvers.push(this.proposalInformation.reviewerGsitId)
					}
					if (this.reviewerUkkpLocalState) {
						approvers.push(this.proposalInformation.reviewerUkkpId)
					}
					if (this.firstApproverDlogLocalState) {
						approvers.push(this.proposalInformation.firstApproverDlogId)
					}
					if (this.secondApproverDlogLocalState) {
						approvers.push(this.proposalInformation.secondApproverDlogId)
					}
					return approvers
				},
				WBS() {
					return this.$store.state.rfp.WBS
				},
        proposalInformation: {
            get() {
                return this.$store.state.rfp.proposalInformation;
            },
            set(value) {
                this.$store.commit("rfp/SET_PROPOSAL_INFORMATION", value);
            },
        },
        proposalInformationProjectDetail() {
          return this.$store.state.projectDetail.proposalInformation;
        },
				errorProposalInformation: {
					get() {
						return this.$store.state.errorRfp.errorProposalInformation
					},
					set(value) {
						this.$store.commit('errorRfp/SET_ERROR_PROPOSAL_INFORMATION', value)
					}
				},
				modal: {
					get() {
						return this.$store.state.modal.modal
					},
					set(value) {
						this.$store.commit('modal/SET_MODAL', value)
					}
				},
				pathEditRfp() {
					return this.$route.name === EDITRFP
				},
				menuDashboard() {
					return this.$store.getters['dashboard/menuDashboard']
				},
				roleKp() {
					const roleName = this.menuDashboard?.applicationRoleName
					return roleName?.includes(KP)
				},
				roleOprKp() {
					return this.menuDashboard?.applicationRoleName === this.SOLUTIP_OPR_KP
				}
    },
    watch: {
			'proposalInformation.approverUkkpName'() {
				this.approverUkkpLocalState = this.proposalInformation.approverUkkpName
			},
			'proposalInformation.directApproverName' () {
				this.directApproverLocalState = this.proposalInformation.directApproverName
			},
			'proposalInformation.reviewerGsitName' () {
				this.reviewerGsitLocalState = this.proposalInformation.reviewerGsitName
			},
			'proposalInformation.reviewerUkkpName' () {
				this.reviewerUkkpLocalState = this.proposalInformation.reviewerUkkpName
			},
			'proposalInformation.firstApproverDlogName' () {
				this.firstApproverDlogLocalState = this.proposalInformation.firstApproverDlogName
			},
			'proposalInformation.secondApproverDlogName' () {
				this.secondApproverDlogLocalState = this.proposalInformation.secondApproverDlogName
			},
			'proposalInformation.projectName'() {
				this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'projectName' })
			},
			'proposalInformation.technicalCoordinatorOfficePhone'() {
				this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'technicalCoordinatorOfficePhone' })
			},
			'proposalInformation.ext'() {
				this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'ext' })
			},
			'proposalInformation.budgetSpent'() {
				this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'budgetSpent' })
			},
			'proposalInformation.kodeWbsLev1'() {
				this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'kodeWbsLev1' })
				this.kodeWbsLev1LocalState = this.proposalInformation.kodeWbsLev1
			},
    },
    methods: {
        inputUpdate(value, key) {
            this.proposalInformation[key] = value;
        },
        changeAdaUkkp(val) {
            if (val === "Ya") {
							this.proposalInformation.adaUkkp = true 
						} else {
							this.proposalInformation.adaUkkp = false;
							this.proposalInformation.approverUkkpName = ''
							this.proposalInformation.approverUkkpId = ''
							this.proposalInformation.unitKerja = ''
							this.approverUkkpLocalState = ''
							this.proposalInformation.reviewerUkkpName = ''
							this.proposalInformation.reviewerUkkpId = ''
							this.reviewerUkkpLocalState = ''
							this.errorProposalInformation.approverUkkpId = { value: false, message: '' }
						}
        },
				userApprover(type) {
					const mapFilterEselon = {
						reviewerGsit: { key: 'APPROVER_GSIT', value: this.proposalInformation.directApproverId },
						reviewerUkkp: { key: 'APPROVER_UKKP', value: this.proposalInformation.approverUkkpId },
						firstApproverDlog: { key: 'APPROVER_DLOG_2', value: this.proposalInformation.secondApproverDlogId },
						secondApproverDlog: { key: 'APPROVER_DLOG_1', value: this.proposalInformation.firstApproverDlogId },
						directApprover: { key:'REVIEWER_GSIT', value: this.proposalInformation.reviewerGsitId },
						approverUkkp: { key: 'REVIEWER_UKKP', value: this.proposalInformation.reviewerUkkpId},
					}
					return `${mapFilterEselon[type].key}:${mapFilterEselon[type].value};`
				},
				async focusApprover(type) {
					this.isLoadingApprover = true
					await this.$store.dispatch('rfp/getApproverUkkp', { role: '', name: this[type+'LocalState'], project: 'RFP', type: this.mapApproverType[type], approvers: this.userApprover(type) })
					this.isLoadingApprover = false
				},
				async inputSearchApprover(event, type) {
					this.isLoadingApprover = true
					await this.$store.dispatch('rfp/getApproverUkkp', { role: '', name: event, project: 'RFP', type: this.mapApproverType[type], approvers: this.userApprover(type) })
					this.isLoadingApprover = false
				},
				async updateApprover(event, type) {
					console.log(event);
					switch (type) {
						case 'directApprover':
							this.proposalInformation.directApproverId = event.userName
							this.proposalInformation.directApproverName = event.name
							this.directApproverLocalState = event.name
							await this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'directApproverId' })
							break;
						case 'approverUkkp':
							this.proposalInformation.approverUkkpId = event.userName;
							this.proposalInformation.approverUkkpName = event.name;
							this.proposalInformation.unitKerja = event.unitKerja;
							this.approverUkkpLocalState = event.name
							await this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'approverUkkpId' })
							break;
						case 'reviewerGsit':
							this.proposalInformation.reviewerGsitId = event.userName;
							this.proposalInformation.reviewerGsitName = event.name;
							this.reviewerGsitLocalState = event.name
							break;
						case 'reviewerUkkp':
							this.proposalInformation.reviewerUkkpId = event.userName;
							this.proposalInformation.reviewerUkkpName = event.name;
							this.reviewerUkkpLocalState = event.name;
							break;
						case 'firstApproverDlog':
							this.proposalInformation.firstApproverDlogId = event.userName;
							this.proposalInformation.firstApproverDlogName = event.name
							this.firstApproverDlogLocalState = event.name;
							await this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'firstApproverDlogId' })

							break;
						case 'secondApproverDlog':
							this.proposalInformation.secondApproverDlogId = event.userName;
							this.proposalInformation.secondApproverDlogName = event.name;
							this.secondApproverDlogLocalState = event.name
							await this.$store.dispatch('errorRfp/checkErrorProposalInformationField', { key: 'secondApproverDlogId' })

							break;
					}
				},
        changeApproverUkkp(event) {
					this.proposalInformation.approverUkkpId = event.userName;
					this.proposalInformation.approverUkkpName = event.name;
          this.proposalInformation.unitKerja = event.unitKerja;
					this.approverUkkpLocalState = event.name
        },
				async inputSearchWBS(event) {
					this.isLoadingWBS = true
					await this.$store.dispatch('rfp/getWBS', { element: event })
					this.isLoadingWBS = false
				},
				updateWBS(event) {
					this.proposalInformation.kodeWbsLev1 = event.wbsElement
					this.kodeWbsLev1LocalState = event.wbsElement
				},
				async focusKodeWBSLev1() {
					this.isLoadingWBS = true
					await this.$store.dispatch('rfp/getWBS', { element: this.kodeWbsLev1LocalState })
					this.isLoadingWBS = false
				},
        changeCheckBox(value) {
            this.proposalInformation.isSoftware = value;
        },
        changeCollapse() {
            this.$emit("collapse");
        },
				validationNumber(e) {
					let keyCode = e.keyCode;
					if (keyCode < 48 || keyCode > 57) {
						e.preventDefault();
					}
				},
				validationPhone(e, phone) {
					let keyCode = e.keyCode;
					if ((phone.length === 0 && keyCode !== 43 && (keyCode < 48 || keyCode > 57)) || 
						(phone.length !== 0 && (keyCode < 48 || keyCode > 57))) {
						e.preventDefault();
					}
				},
				async blurApproverUkkp() {
					if (!this.approverUkkpLocalState) {
						this.proposalInformation.approverUkkpName = ''
						this.proposalInformation.approverUkkpId = ''
						this.proposalInformation.unitKerja = ''
					}
					this.approverUkkpLocalState = this.proposalInformation.approverUkkpName
				},
				async blurApprover(type) {
					switch (type) {
						case 'directApprover':
							if (!this.directApproverLocalState) {
								this.proposalInformation.directApproverName = ''
								this.proposalInformation.directApproverId = ''
							}
							this.directApproverLocalState = this.proposalInformation.directApproverName
							break;
						case 'approverUkkp':
							if (!this.approverUkkpLocalState) {
								this.proposalInformation.approverUkkpName = ''
								this.proposalInformation.approverUkkpId = ''
								this.proposalInformation.unitKerja = ''
							}
							this.approverUkkpLocalState = this.proposalInformation.approverUkkpName
							break;
						case 'reviewerGsit':
							if (!this.reviewerGsitLocalState) {
								this.proposalInformation.reviewerGsitName = ''
								this.proposalInformation.reviewerGsitId = ''
							}
							this.reviewerGsitLocalState = this.proposalInformation.reviewerGsitName
							break;
						case 'reviewerUkkp':
							if (!this.reviewerUkkpLocalState) {
								this.proposalInformation.reviewerUkkpName = ''
								this.proposalInformation.reviewerUkkpId = ''
							}
							this.reviewerUkkpLocalState = this.proposalInformation.reviewerUkkpName
							break;
						case 'firstApproverDlog':
							if (!this.firstApproverDlogLocalState) {
								this.proposalInformation.firstApproverDlogName = ''
								this.proposalInformation.firstApproverDlogId = ''
							}
							this.firstApproverDlogLocalState = this.proposalInformation.firstApproverDlogName
							break;
						case 'secondApproverDlog':
							if (!this.secondApproverDlogLocalState) {
								this.proposalInformation.secondApproverDlogName = ''
								this.proposalInformation.secondApproverDlogId = '' 
							}
							this.secondApproverDlogLocalState = this.proposalInformation.secondApproverDlogName
							break;
					}
				},
				blurKodeWBSLev1() {
					if (!this.kodeWbsLev1LocalState) {
						this.proposalInformation.kodeWbsLev1 = ''
					}
					this.kodeWbsLev1LocalState = this.proposalInformation.kodeWbsLev1
				},
				validationBudgetSpent(e) {
					let keyCode = e.keyCode
					if ((keyCode > 47 && keyCode < 58) || keyCode === 46) {
						return
					}
					e.preventDefault();
				},
				inputBudgetSpent() {
					this.proposalInformation.budgetSpent = formatThousandSeparate(this.proposalInformation.budgetSpent.split('.').join(''), 'idr')
				},
				handleInputBudgetSpent(e) {
					const el = e.target;
					const formatted = formatThousandSeparate(el.value.split('.').join(''), 'idr')
					const count = formatted?.split('.').length
					const count2 = el.value?.split('.').length
					let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
					this.$nextTick(() => {
						el.setSelectionRange(sel, sel);
					});
				}
    },
    async mounted() {
			//get manager direct approver
			if (!this.proposalInformation.directApproverId && !this.$route.params.id) {
				const responseMgr = await this.$store.dispatch('rfp/getRfpMgr')
				this.proposalInformation.directApproverId = responseMgr?.data?.userId || ''
				this.proposalInformation.directApproverName = responseMgr?.data?.userName || ''
			}
			window.addEventListener("resize", () => this.windowWidth = window.innerWidth);
    },
    destroyed() {
        window.removeEventListener("resize", () => this.windowWidth = window.innerWidth);
    },
    components: { AutocompleteSolutip, InputSolutip }
}
</script>

